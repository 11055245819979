import React from "react";
import ClockWiseIcon from "../../../../svg/ClockWiseIcon";
import InfoIcon from "../../../../svg/InfoIcon";

function BottomSection({
  audioStream,
  stateHoverFeedback,
  openFeedback,
  setHoverFeedback,
}) {
  return (
    <div className="self-stretch h-[108px] p-2 bg-white border-t border-neutral-300 flex-col justify-start items-start gap-2 flex">
      <div className="w-full items-center justify-center flex">
        <div className="p-1.5 justify-center items-center gap-4 flex">
          {audioStream && <ClockWiseIcon />}
          <div className="relative">
            {stateHoverFeedback ? (
              <div className="absolute left-[-87px] top-[-90px] drop-shadow flex-col z-50 w-52 h-20 px-4 py-3 bg-gray-50 rounded-lg justify-start items-start gap-1 inline-flex">
                <p className="self-stretch text-slate-800 text-sm leading-tight">
                  Voice captcha verifies your age range by voice.
                </p>
                <p
                  className="text-blue-500 text-sm leading-tight cursor-pointer"
                  onClick={openFeedback}
                >
                  Leave a feedback
                </p>
              </div>
            ) : (
              <></>
            )}

            <div
              className="cursor-pointer"
              onClick={() => setHoverFeedback(!stateHoverFeedback)}
            >
              <InfoIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <div className="self-stretch justify-center items-center gap-1 inline-flex">
          <div className="text-neutral-500 text-base font-medium font-['Inter'] leading-normal">
            We do not record or store your voice.
          </div>
          <div className="text-blue-500 text-base font-medium font-['Inter'] leading-normal">
            Learn More
          </div>
        </div>
        <div className="self-stretch justify-center items-center gap-1 inline-flex">
          <div className="text-neutral-500 text-base font-medium font-['Inter'] leading-normal">
            Kindly ensure the entire sentence is articulated for an accurate prediction.
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomSection;
