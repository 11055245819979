import React from "react";
import { useModal } from "./hooks";
import { BottomSection, MiddleSection, RecordingView } from "./components";

function Modal({ isOpen, closeModal, setApproved }) {
  const {
    datas: {
      sentence,
      hasSound,
      audioStream,
      stateRecording,
      stateResponseAge,
      stateResponseAgeProb,
      stateResponseGender,
      stateResponseGenuineness,
      stateHoverFeedback,
    },
    methods: { handleMic, openFeedback, setHoverFeedback, resetMic },
  } = useModal({ isOpen, closeModal, setApproved });

  return (
    <div
      className={`${
        isOpen ? "absolute" : "hidden"
      } z-50 w-full h-full inline-flex`}
    >
      <div className="absolute -z-50 bg-black opacity-25 w-full h-full" />
      <div className="w-[447px]  h-[540px] bg-white rounded-md shadow border-2 border-neutral-300 flex-col m-auto justify-center items-center gap-[50px] inline-flex">
        <RecordingView
          audioStream={audioStream}
          handleMic={handleMic}
          sentence={sentence}
        />
        <MiddleSection
          handleMic={handleMic}
          stateRecording={stateRecording}
          stateResponseAge={stateResponseAge}
          stateResponseAgeProb={stateResponseAgeProb}
          stateResponseGender={stateResponseGender}
          stateResponseGenuineness={stateResponseGenuineness}
          resetMic={resetMic}
          hasSound={hasSound}
        />
        <BottomSection
          audioStream={audioStream}
          openFeedback={openFeedback}
          setHoverFeedback={setHoverFeedback}
          stateHoverFeedback={stateHoverFeedback}
        />
      </div>
    </div>
  );
}

export default Modal;
