import React, { useRef, useState } from "react";
import axios from "axios";
import LottieCheck from "./lottie-check.json";
import { Player } from "@lottiefiles/react-lottie-player";

// import Modal from "../../components/Modal";

const FeedbackSubmit = () => {
  const defaultForm = {
    user: "",
    email: "",
    feedback: "",
  };

  const errorForm = {
    user: false,
    email: false,
    feedback: false,
  };
  const lottieRef = useRef();
  const [formState, setFormState] = useState(defaultForm);
  const [errorFormState, setErrorForm] = useState(errorForm);
  const [submittedState, setSubmitState] = useState(false);

  function handleChangeFormFeedback(e) {
    const { name, value } = e.target;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function submitFeedback() {
    // reset state error
    setErrorForm(errorForm);

    let isError;
    // check user
    if (formState.user === "") {
      isError = true;
      setErrorForm((prevState) => ({
        ...prevState,
        user: true,
      }));
    }

    // email only check if its valid or not.
    if (formState.email !== "" && !isValidEmail(formState.email)) {
      isError = true;
      setErrorForm((prevState) => ({
        ...prevState,
        email: true,
      }));
    }

    // check feedback
    if (formState.feedback === "") {
      isError = true;
      setErrorForm((prevState) => ({
        ...prevState,
        feedback: true,
      }));
    }

    if (isError) {
      return;
    }

    axios
      .post(process.env.REACT_APP_SERVER_URL + "/api/feedback", formState)
      .then(() => {
        setSubmitState(true);
        setFormState(defaultForm);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <div className="flex items-center justify-center min-h-screen transform transition-all">
      <div
        className={`w-3/4 ${
          submittedState ? "md:max-w-md" : "md:max-w-2xl"
        } p-8 border border-gray-400 rounded-xl flex flex-col gap-8`}
      >
        <div className="w-full text-center flex flex-col gap-2">
          <p className="text-black font-bold text-[40px] leading-[48px]">
            Leave a Feedback
          </p>
          <p className=" text-slate-800 leading-normal">
            Encountered any problem during the age verification? Let us know so
            we can help you.
          </p>
        </div>
        {submittedState ? (
          <div className="w-full flex flex-col justify-items-center">
            <Player
              autoplay
              src={LottieCheck}
              ref={lottieRef}
              loop
              className="w-16 h-16"
            />
            <p className="text-center w-full text-slate-900 leading-normal mt-2">
              Thank you for the feedback!
            </p>{" "}
            <button
              className="w-fit px-4 py-2 border border-slate-700 rounded-md mx-auto mt-7 leading-loose"
              onClick={() => setSubmitState(false)}
              aria-hidden
            >
              Submit Another
            </button>
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-4">
              <label className="flex flex-col ">
                <p className=" text-slate-700 font-bold leading-loose">
                  Verification for
                </p>
                <input
                  name={"user"}
                  type={"text"}
                  value={formState.user}
                  onChange={handleChangeFormFeedback}
                  className="border border-gray-400 text-slate-800 rounded-lg outline-none px-4 py-2 w-full"
                />
                {errorFormState.user ? (
                  <p className="w-full text-red-600  leading-loose">
                    Enter the company you’re verifying for.
                  </p>
                ) : (
                  <></>
                )}
              </label>
              <label className="flex flex-col ">
                <p className=" text-slate-700 font-bold leading-loose">
                  Your email (optional)
                </p>
                <input
                  name={"email"}
                  type={"email"}
                  value={formState.email}
                  onChange={handleChangeFormFeedback}
                  className="border border-gray-400 text-slate-800 rounded-lg outline-none px-4 py-2 w-full"
                />
                {errorFormState.email ? (
                  <p className="w-full text-red-600  leading-loose">
                    Please enter a valid email.
                  </p>
                ) : (
                  <></>
                )}
              </label>
              <label className="flex flex-col ">
                <div className="flex flex-row w-full justify-between">
                  <p className=" text-slate-700 font-bold leading-loose">
                    Your feedback
                  </p>
                  <p className="text-slate-700 leading-loose">
                    {formState.feedback.length}/1000
                  </p>
                </div>
                <textarea
                  maxLength={1000}
                  rows={10}
                  name={"feedback"}
                  value={formState.feedback}
                  onChange={handleChangeFormFeedback}
                  className="w-full border border-gray-400 text-slate-800 px-4 py-3 rounded-lg resize-none"
                />
                {errorFormState.feedback ? (
                  <p className="w-full text-red-600 leading-loose">
                    Enter your message here.
                  </p>
                ) : (
                  <></>
                )}
              </label>
            </div>
            <div className="w-full justify-end flex">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-md leading-loose"
                onClick={submitFeedback}
                aria-hidden
              >
                Submit Feedback
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FeedbackSubmit;
