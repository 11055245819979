import React, { useRef, useState } from "react";
import MicrophoneIcon from "../../../../svg/MicrophoneIcon";
import { Player } from "@lottiefiles/react-lottie-player";
import Success from "../lottie-files/success.json";
import Loading from "../lottie-files/loading.json";
import SadFace from "../../../../svg/SadFace";

function MiddleSection({
  handleMic,
  stateRecording,
  stateResponseAge,
  stateResponseAgeProb,
  stateResponseGender,
  stateResponseGenuineness,
  resetMic,
  hasSound,
}) {
  const [isStarted, setStart] = useState(false);

  const refSuccess = useRef();
  const refLoad = useRef();

  function startRecording() {
    setStart(true);
    handleMic();
  }

  return (
    <div className="w-1/2 flex-col justify-center items-center gap-0.5 flex">
      {(() => {
        switch (stateRecording) {
          case "recording":
            return (
              <>
                <div className="pl-[14.50px] pr-[12.50px] pt-3.5 pb-[13px] justify-center items-center inline-flex">
                  <div
                    onClick={startRecording}
                    className="cursor-pointer self-stretch p-3.5 bg-blue-500 rounded-[200px] justify-center items-center gap-2 inline-flex"
                  >
                    <div className="w-[61px] h-[61px] relative">
                      <MicrophoneIcon />
                    </div>
                  </div>
                </div>
                {isStarted ? (
                  <>
                    <p className="w-full text-center text-slate-900 text-xl font-medium leading-7">
                      Listening...
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </>
            );
          case "done":
            return (
              <>
                {hasSound.current ? (
                  <div className="mx-auto flex flex-col items-center">
                    <Player
                      autoplay
                      src={Success}
                      ref={refSuccess}
                      loop
                      className="w-16 h-16"
                    />
                    <p className="text-center text-slate-900 text-xs font-medium leading-none mt-2">
                      Age Group
                    </p>
                    <AgeGroup age_group={stateResponseAge} age_prob={stateResponseAgeProb} gender={stateResponseGender} genuineness={stateResponseGenuineness} />
                  </div>
                ) : (
                  <div className="mx-auto flex flex-col items-center">
                    <SadFace />
                    <p className="text-center text-slate-900 text-sm font-semibold leading-7 mt-2">
                      We can&apos;t hear you.
                    </p>
                    <p
                      className="text-blue-500 text-sm font-medium cursor-pointer"
                      onClick={resetMic}
                    >
                      Retry?
                    </p>
                  </div>
                )}
              </>
            );
          default:
            return (
              <Player
                autoplay
                src={Loading}
                ref={refLoad}
                loop
                className="w-16 h-16"
              />
            );
        }
      })()}
    </div>
  );
}

function AgeGroup({ age_group, age_prob, gender, genuineness }) {
  console.log("prob", age_prob);
  console.log("gender", gender);
  console.log("genuineness", genuineness);
  console.log("age_group", age_group);
  return (
    <p className="text-center text-slate-900 text-sm font-semibold leading-normal mt-0.5">
      {/* {age_group === 0 ? "Children (< 13)" : age_group===1 ? "Teenage (13-18)" : "Adult (> 18)"} */}
      {age_group} <br/>
      Age probability: {age_prob.toFixed(2)} <br/>
      Gender: {gender} <br/>
      Genuiness: {genuineness} <br/>
    </p>
  );
}

export default MiddleSection;
