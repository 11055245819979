import React from "react";

const InfoIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 28C23.1274 28 28.5 22.6274 28.5 16C28.5 9.37258 23.1274 4 16.5 4C9.87258 4 4.5 9.37258 4.5 16C4.5 22.6274 9.87258 28 16.5 28Z"
        stroke="#808080"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 15C15.7652 15 16.0196 15.1054 16.2071 15.2929C16.3946 15.4804 16.5 15.7348 16.5 16V21C16.5 21.2652 16.6054 21.5196 16.7929 21.7071C16.9804 21.8946 17.2348 22 17.5 22"
        stroke="#808080"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 12C16.8284 12 17.5 11.3284 17.5 10.5C17.5 9.67157 16.8284 9 16 9C15.1716 9 14.5 9.67157 14.5 10.5C14.5 11.3284 15.1716 12 16 12Z"
        fill="#808080"
      />
    </svg>
  );
};

export default InfoIcon;
