import React from "react";
import CheckIcon from "../../../../svg/CheckIcon";

function RecordingView({ audioStream, sentence }) {
  return (
    <>
      <div className="self-stretch h-[140px] px-3.5 py-1 flex-col justify-start items-start gap-3 flex">
        <div className="self-stretch h-[132px] rounded-lg flex-col justify-start items-start gap-3 flex">
          {!sentence && (
            <>
              {audioStream ? (
                <div className="w-[419px] h-12 px-3 py-2 rounded justify-start items-start gap-4 inline-flex">
                  <div className="justify-start items-start flex">
                    <div className="w-8 h-8 relative">
                      <CheckIcon />
                    </div>
                  </div>
                  <div className="grow shrink basis-0 text-green-600 text-xl font-medium font-['Inter'] leading-7">
                    Allow microphone to use.
                  </div>
                </div>
              ) : (
                <div className="self-stretch px-3 py-2 bg-blue-500 rounded justify-start items-start gap-4 inline-flex">
                  <div className="justify-start items-start flex">
                    <div className="w-8 h-8 relative p-2 text-white">1</div>
                  </div>
                  <div className="grow shrink basis-0 text-white text-xl font-medium font-['Inter'] leading-7">
                    Allow microphone to use.
                  </div>
                </div>
              )}
            </>
          )}
          <div
            className={audioStream ? "bg-blue-500 text-white" : "text-zinc-500"}
          >
            <div className="self-stretch px-3 py-2 rounded justify-start items-start gap-4 inline-flex">
              <div className="justify-start items-start flex">
                <div className="w-8 h-8 relative p-2 ">2</div>
              </div>
              <div className="grow shrink basis-0 text-xl font-medium font-['Inter'] leading-7">
                Click the microphone and read the following sentence.
              </div>
            </div>
          </div>
          {sentence && (
            <div className="w-[419px] h-8 text-center text-neutral-700 text-xl font-semibold font-['Inter'] leading-7">
              “{sentence}”
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default RecordingView;
