import React from 'react';

const MicrophoneIcon = () => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Microphone" clipPath="url(#clip0_533_1364)">
        <path
          id="Vector"
          d="M40.0312 15.25C40.0312 9.98604 35.764 5.71875 30.5 5.71875C25.236 5.71875 20.9688 9.98604 20.9688 15.25V30.5C20.9688 35.764 25.236 40.0312 30.5 40.0312C35.764 40.0312 40.0312 35.764 40.0312 30.5V15.25Z"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M30.5 47.6562V55.2812"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M47.6562 30.5C47.6562 35.0501 45.8487 39.4139 42.6313 42.6313C39.4139 45.8487 35.0501 47.6562 30.5 47.6562C25.9499 47.6562 21.5861 45.8487 18.3687 42.6313C15.1513 39.4139 13.3438 35.0501 13.3438 30.5"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_533_1364">
          <rect width="61" height="61" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MicrophoneIcon;