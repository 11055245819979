import React from "react";

const ClockWiseIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5 13H29.5V7"
        stroke="#808080"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0501 24C22.4776 25.4838 20.5028 26.4713 18.3723 26.8391C16.2418 27.207 14.0501 26.9388 12.0712 26.0682C10.0922 25.1976 8.41369 23.7631 7.24532 21.944C6.07695 20.1249 5.47055 18.0018 5.50189 15.8401C5.53323 13.6783 6.20092 11.5737 7.42153 9.7892C8.64214 8.00472 10.3616 6.6195 12.3649 5.80662C14.3683 4.99375 16.5668 4.78926 18.6857 5.21871C20.8046 5.64815 22.75 6.6925 24.2788 8.22124L29.5001 13"
        stroke="#808080"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockWiseIcon;
