import React, { useState } from "react";
import Modal from "../../components/Modal";

const VoiceCheckbox = () => {
  const [modalState, setModalState] = useState(false);
  const [checkState, setCheckState] = useState(false); // set state checked for later after mic is working

  function handleOpenCaptcha() {
    if (!checkState) {
      setModalState(true);
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="border-2 w-96 border-[#D2D2D2] bg-[#F9F9F9] rounded shadow justify-between items-center inline-flex px-4 py-5">
        <div className="justify-start items-center gap-4">
          <label className="flex flex-row items-center">
            <input
              type="checkbox"
              className="p-2 mr-4 w-10 h-10"
              onClick={handleOpenCaptcha}
              readOnly
              checked={checkState}
            />
            <p className="text-black text-xl tracking-tight">
              Verify My Age Group
            </p>
          </label>
        </div>
        <img
          width={37}
          height={37}
          src="/logo_ailiverse.png"
          alt="ailiverse-logo"
          className="drop-shadow-lg"
        />
      </div>
      <Modal
        isOpen={modalState}
        closeModal={() => setModalState(false)}
        setApproved={() => setCheckState(true)}
      />
    </div>
  );
};

export default VoiceCheckbox;
