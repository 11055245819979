import axios from "axios";
import { useEffect, useRef, useState } from "react";

async function askForAudioPermission() {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    return stream;
  } catch (error) {
    console.error("Error getting audio permission:", error);
    return null;
  }
}

export function useModal({ isOpen, closeModal, setApproved }) {
  const [audioStream, setAudioStream] = useState(null);
  const mediaRecorderRef = useRef(null);
  const [sentence, setSentence] = useState("");
  const [stateHoverFeedback, setHoverFeedback] = useState(false);
  const [stateResponseAge, setStateResponseAge] = useState("");
  const [stateResponseAgeProb, setStateResponseAgeProb] = useState(0.0);
  const [stateResponseGender, setStateResponseGender] = useState("");
  const [stateResponseGenuineness, setStateResponseGenuineness] = useState(100);
  const [stateRecording, setStateRecording] = useState("recording"); // recording, loading, done
  const hasSound = useRef(false);
  const analyserRef = useRef(null);
  const startTimeRef = useRef(0);
  const localAudioChunks = useRef([])
  const recordingIntervalRef = useRef(null);

  // code for check mic function
  function checkForSilence() {
    const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
    analyserRef.current.getByteFrequencyData(dataArray);

    // Calculate the average volume
    const averageVolume = Array.from(dataArray).reduce((acc, value) => acc + value, 0) / dataArray.length;

    // Convert averageVolume to decibels
    const averageVolumeInDecibels = 20 * Math.log10(averageVolume / 256);

    // If the average volume is below a certain threshold (indicating silence), stop recording
    const elapsedTime = (Date.now() - startTimeRef.current) / 1000; // Convert milliseconds to seconds
    if (averageVolumeInDecibels < -70 && !hasSound.current) {
      hasSound.current = false;
    } else {
      hasSound.current = true;
    }

    if (elapsedTime >= 10) {
      clearInterval(recordingIntervalRef.current)
      mediaRecorderRef.current.stop();
    }
  };

  // triggered when recording stops
  async function onSilence(chuck) {
    // setup file recording for the AI
    const audioBlob = new Blob(chuck, { type: 'audio/webm' });

    const file = new File([audioBlob], `recording-${new Date()}.webm`);
    const formData = new FormData();
    formData.append("file", file);
    setStateRecording("loading");

    // send to the Backend and AI
    await axios
      .post(process.env.REACT_APP_SERVER_URL + "/api/recording", formData)
      .then(async (response) => {
        console.log(response);
        setStateResponseAge(response.data.age_group);
        setStateResponseAgeProb(response.data.age_prob);
        setStateResponseGender(response.data.gender);
        setStateResponseGenuineness(response.data.genuineness);
        setStateRecording("done");
        setApproved(true);
        await new Promise((resolve) => setTimeout(resolve, 5000));
        // closeModal();
      })
      .catch((error) => {
        console.error("Error:", error);
        setStateResponseAge(0);
        setStateRecording("done");
      });
  }

  useEffect(() => {
    if (isOpen) {
      askForAudioPermission().then((stream) => {
        if (stream) {
          setAudioStream(stream);
        }
      });
    }
  }, [isOpen]);

  function handleMic() {
    axios
      .get(process.env.REACT_APP_SERVER_URL + "/api/sentence")
      .then(async (response) => {
        setSentence(response.data.sentence);
        // setup recording after get sncence and add to ref
        const mediaRecorder = new MediaRecorder(audioStream);
        mediaRecorderRef.current = mediaRecorder;
        // start
        mediaRecorderRef.current.start();

        // get the audio chucks
        mediaRecorderRef.current.ondataavailable = (event) => {
          /* add the data to the recordedDataArray */
          // if (typeof event.data === "undefined") return;
          console.log(event.data);
          if (event.data.size > 0) {
            localAudioChunks.current.push(event.data);
          }
        };

        // Create an AnalyserNode to analyze audio data
        const audioContext = new (window.AudioContext || window.AudioContext)();
        analyserRef.current = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(audioStream);
        source.connect(analyserRef.current);

        // detect audio silence (below 70db) after 2 seconds
        //detectSilence(audioStream, 2000, -70);
        startTimeRef.current = Date.now();

        // set audio stop function to ref
        mediaRecorderRef.current.onstop = () => {
          if (hasSound.current) {
            onSilence(localAudioChunks.current)
            return;
          } else {
            setStateRecording("done");
          }
        };

        recordingIntervalRef.current = setInterval(checkForSilence, 1000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  function resetMic() {
    setStateRecording("recording");
    hasSound.current = false
    handleMic();
  }

  function openFeedback() {
    setHoverFeedback(false);
    window.open("/feedback", "_blank", "noopener,noreferrer");
  }

  return {
    datas: {
      sentence,
      hasSound,
      audioStream,
      stateRecording,
      stateResponseAge,
      stateResponseAgeProb,
      stateResponseGender,
      stateResponseGenuineness,
      stateHoverFeedback,
    },
    methods: {
      resetMic,
      handleMic,
      openFeedback,
      setHoverFeedback,
    },
  };
}
