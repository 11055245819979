import React from "react";

const SadFace = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_794_1421)">
        <path
          d="M16.5 28.5C23.1274 28.5 28.5 23.1274 28.5 16.5C28.5 9.87258 23.1274 4.5 16.5 4.5C9.87258 4.5 4.5 9.87258 4.5 16.5C4.5 23.1274 9.87258 28.5 16.5 28.5Z"
          stroke="#071B32"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M12 15.5C12.8284 15.5 13.5 14.8284 13.5 14C13.5 13.1716 12.8284 12.5 12 12.5C11.1716 12.5 10.5 13.1716 10.5 14C10.5 14.8284 11.1716 15.5 12 15.5Z"
          fill="#071B32"
        />
        <path
          d="M21 15.5C21.8284 15.5 22.5 14.8284 22.5 14C22.5 13.1716 21.8284 12.5 21 12.5C20.1716 12.5 19.5 13.1716 19.5 14C19.5 14.8284 20.1716 15.5 21 15.5Z"
          fill="#071B32"
        />
        <path
          d="M21.5 22.5C20.4625 20.7062 18.7213 19.5 16.5 19.5C14.2787 19.5 12.5375 20.7062 11.5 22.5"
          stroke="#071B32"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_794_1421">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SadFace;
